import styled from 'styled-components';
import linkedin from '../Images/Linkedin.png';
import twitter from '../Images/twitter.png';
import facebook from '../Images/Facebook.png';
import instagram from '../Images/instagram.png';
import img1 from '../Images/img1.png';
import img2 from '../Images/img2.png';
import img3 from '../Images/img3.png';
import img4 from '../Images/img4.png';
import img5 from '../Images/img5.png';
import img6 from '../Images/img6.png';
import img7 from '../Images/img7.png';
import img8 from '../Images/img8.png';
import { Link } from 'react-router-dom';

const Comp1Wrapper = styled.div`
  height: auto;
  margin: 0;
  font-family:Instrument Sans;
`;

const TopSection = styled.div`
  text-align: center;
  background: #f1f1f1;
   background-image: 
    linear-gradient(to right, rgba(0, 0, 0, 0.05) 1px, transparent 1px),
    linear-gradient(to bottom, rgba(0, 0, 0, 0.05) 1px, transparent 1px);
  background-size: 140px 100px; 
  margin: 0;
  padding: 80px 10px;
  padding-bottom: 180px;
  position:relative;
  z-index:-1;

  h1 {
    font-size: 56px;
    font-weight: 600;
    line-height: 60px;
  }

  p {
    color: #303030;
    font-size: 20px;
    font-weight: 500;
    line-height: 26px;
  }

  @media (max-width: 992px) {
    h1 {
      font-size: 44px;
      line-height: 48px;
    }
    p {
      font-size: 18px;
      line-height: 24px;
    }
  }

  @media (max-width: 576px) {
    h1 {
      font-size: 36px;
      line-height: 40px;
    }
    p {
      font-size: 16px;
      line-height: 22px;
    }
  }
`;

const MidSection = styled.div`
  padding: 0 80px;
  padding-bottom: 80px;
  background-color: white;
  border: 1px solid transparent;

  @media (max-width: 1200px) {
    padding: 0 40px;
    padding-bottom: 60px;
  }

  @media (max-width: 768px) {
    padding: 0 20px;
    padding-bottom: 40px;
  }
`;

const CardsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, auto);
  column-gap: 20px;
  row-gap: 20px;
  margin-top: -100px;

  @media (max-width: 1200px) {
    grid-template-columns: repeat(2, auto);
    justify-content: center;
  }

  @media (max-width: 768px) {
    grid-template-columns: repeat(1, auto);
  }
`;

const Card = styled.div`
  width: 400px;
  height: 500px;
  border-radius: 10px;
  background: #d8e7ef;
  text-align: center;
  padding: 30px 20px;
  // box-sizing: border-box;

  img {
    height: 325px;
    width: 325px;
    border-radius: 50%;
  }

  h2 {
    font-size: 24px;
    font-weight: 600;
  }

  p {
    font-size: 16px;
    font-weight: 400;
    color: #246bfd;
  }

  @media (max-width: 992px) {
    width: 350px;
    height: 450px;

    img {
      height: auto;
      width: 100%;
    }
  }

  @media (max-width: 768px) {
    width: 100%;
    max-width: 400px;
    height: auto;
     box-sizing: border-box;
  }
`;

const IconsContainer = styled.div`
  img {
    width: 30px !important;
    height: 30px !important;
    margin: 0 5px;
    border-radius: 4px;
  }
`;

const BottomSection = styled.div`
  background-color: black;
  padding: 60px 40px;
  text-align: center;

  h1 {
    font-size: 52px;
    font-weight: 500;
    line-height: 70px;
    color: white;
  }

  a {
    background-color: white;
    padding: 12px 20px;
    border-radius: 40px;
    color: black;
    font-weight: bold;
    text-decoration: none;
  }

  @media (max-width: 992px) {
    h1 {
      font-size: 42px;
      line-height: 56px;
    }
  }

  @media (max-width: 576px) {
    h1 {
      font-size: 32px;
      line-height: 42px;
    }

    a {
      padding: 10px 16px;
      font-size: 14px;
    }
  }
`;

function Team() {
  return (
    <Comp1Wrapper>
      <TopSection>
        <h1>Our Executive Team</h1>
        <p>
          Our success is a result of teamwork and building upon our technical expertise
          <br />
          and creative style providing a full-service solution to our clients.
        </p>
      </TopSection>
      <MidSection>
        <CardsContainer>
          {[img1, img2, img3, img4, img5, img6, img7, img1,img8].map((imgSrc, index) => (
            <Card key={index}>
              <img src={imgSrc} alt="error" />
              <h2>Kevin Martin</h2>
              <p>Managing Director</p>
              <IconsContainer>
                <img src={linkedin} alt="error" />
                <img src={twitter} alt="error" />
                <img src={facebook} alt="error" />
                <img src={instagram} alt="error" />
              </IconsContainer>
            </Card>
          ))}
        </CardsContainer>
      </MidSection>
      <BottomSection>
        <h1>Looking For A New Opportunity? Good. We're Hiring</h1>
        <Link to='/careers'><a>Available Positions</a></Link>
      </BottomSection>
    </Comp1Wrapper>
  );
}

export default Team;
