import React, { useEffect } from "react";
import {
  AllMainSec1,
  MainSec1,
  LeftSec1,
  LeftSec1H2,
  LeftSec1P,
  GetBtnSec1,
  GetBtnSec1Button,
  RightSec1,
  RightSec1Img,
  AllMainSec2,
  MainHeadSec2,
  MainSec2,
  LeftSec2,
  RightSec2,
  HeadSec2Below,
  AllMainSec5,
  MainSec5,
  Sec5Content,
  Sec5Img1,
  LeftSec5Content,
  RightSec5Content,
  Sec5Img2,
  AllMainSec6,
  MainSec6,
  LeftSec6,
  CenterSec6,
  RightSec6,
  AllMainSec7,
  AllImagesSec7,
  Row,
  AllMainSec11,
  MainSec11,
  LeftSec11,
  RightSec11,
  OutcomeSec11,
  OutcomeSec,
  OutcomeSec2,
  AllMainSec12,
  MainSec12,
  AllMainSec13,
  MainSec13,
  GetInTouchBtn,
  CaseStudyWrapper,
  MainSec7,
  Framework,
  RightSection2,
  Requirements,
} from "./CaseStudyStyle";
import { useState } from "react";
import { useParams } from "react-router-dom";

const businessBay = {
  section1: {
    title: "BusinessBay",
    tagLine: "Bridging Sustainability and Connectivity.",
    btnText: "Register Your Business",
    bannerImage: "BusinessBaycsbanner.png",
  },

  section2: {
    overview: `Revolutionizing and empowering Connections with Next-Generation
                Digital Cards. BusinessBay specializes in premium, eco-friendly
                business cards crafted for sustainability and seamless digital
                integration. With BusinessBay, networking is smarter, greener,
                and more impactful.`,
    founded: "02 January 2025",
    industry: "Social Media",
    scope: "UI/UX Design, Brand Design, Development",
  },
  section3: {
    requirement: `BusinessBay envisions a website that goes beyond offering NFC
            business cards, serving as a comprehensive e-commerce solution for
            both B2B and B2C needs. With intuitive navigation and streamlined
            resume creation, the platform aims to deliver an all-in-one
            experience tailored for modern professionals.`,
  },
  section4: {
    role: `Developed a seamless, user-friendly platform with advanced 
    features to elevate your experience with BusinessBay. Designed by
     our expert team, the platform ensures effortless NFC card activation 
     and offers a comprehensive business solution tailored for success.`,
    roleImage: "bb_cs_role.png",
    challenge: `In today’s digital age, social media platforms play a crucial
                  role in connecting people. Our client envisioned a unique app
                  that helps users achieve personal milestones through
                  gamification. The goal was to combine social interaction with
                  personal growth, allowing users to set goals, track progress,
                  and celebrate achievements in a fun and engaging way.`,
    challengeImage: "bb_cs_challenge.png",
    profile: `In today’s digital age, social media platforms play a crucial
                  role in connecting people. Our client envisioned a unique app
                  that helps users achieve personal milestones through
                  gamification. The goal was to combine social interaction with
                  personal growth, allowing users to set goals, track progress,
                  and celebrate achievements in a fun and engaging way.`,
    profileImage: "bb_cs_challenge2.png",
  },

  section7: {
    img1: "s1.png",
    img2: "s2.png",
    img3: "s3.png",
    img4: "s4.png",
    img5: "s5.png",
    img6: "s6.png",
    img7: "s7.png",
    img8: "s8.png",
    img9: "s9.png",
    img10: "s10.png",
    img11: "s11.png",
    img12: "s12.png",
    img13: "s13.png",
    img14: "s14.png",
    img15: "s15.png",
    img16: "s16.png",
    img17: "s17.png",
  },

  section11: {
    content: [
      { value: "500K+", title: "Active Users" },
      { value: "--", title: "Global Reach" },
      { value: "--", title: "Sustainable Practices" },
    ],
    image: "result.png",
  },
  testimonial: {
    content: `The team’s attention to detail and commitment to our vision were
            exceptional. The app exceeded our expectations in design and
            functionality.`,
    name: "Edward Johnson",
    company: "Business Bay",
  },
};

const vuezen = {
  section1: {
    title: "Vuezen",
    tagLine: "See the world in a better way!!!",
    btnText: "Get In Touch",
    bannerImage: "vuezenBanner.png",
  },

  section2: {
    overview: `Crafted with precision and passion, each frame embodies a fusion of contemporary design and timeless elegance. Their commitment to excellence extends beyond aesthetics; their lenses offer unparalleled optical clarity and protection, ensuring an impeccable visual experience.`,
    founded: "02 January 2025",
    industry: "Social Media",
    scope: "UI/UX Design, Brand Design, Development",
  },
  section3: {
    requirement: `The development of an e-commerce website for eyewear involves a blend of customization, lens technology and material user-centric/ premium design. By focusing on these elements, a platform needs to be developed that not only meets customer needs but also stands out in the competitive eyewear market.`,
  },
  section4: {
    role: `Develop an intuitive, user-friendly online store that offers seamless browsing, personalized recommendations, and secure transactions, ensuring customers can conveniently access eyewear anytime, anywhere. Leverage advanced analytics tools to gain insights into customer preferences, optimize inventory management, forecast market trends, and drive growth.`,
    roleImage: "vz_role.png",
    challenge: `In today’s digital age, social media platforms play a crucial
                      role in connecting people. Our client envisioned a unique app
                      that helps users achieve personal milestones through
                      gamification. The goal was to combine social interaction with
                      personal growth, allowing users to set goals, track progress,
                      and celebrate achievements in a fun and engaging way.`,
    challengeImage: "vz_challange.png",
    profile: `In today’s digital age, social media platforms play a crucial
                      role in connecting people. Our client envisioned a unique app
                      that helps users achieve personal milestones through
                      gamification. The goal was to combine social interaction with
                      personal growth, allowing users to set goals, track progress,
                      and celebrate achievements in a fun and engaging way.`,
    profileImage: "vz_profile.png",
  },

  section7: {
    img1: "v1.png",
    img2: "v2.png",
    img3: "v3.png",
    img4: "v4.png",
    img5: "v5.png",
    img6: "v6.png",
    img7: "v7.png",
    img8: "v8.png",
    img9: "v9.png",
    img10: "v10.png",
    img11: "v11.png",
    img12: "v12.png",
    img13: "v13.png",
    img14: "v14.png",
    img15: "v15.png",
    img16: "v16.png",
    img17: "v17.png",
  },

  section11: {
    content: [
      { value: "1K+", title: "Customers Global Shipping" },
      { value: "90%", title: "Customer Satisfaction" },
    ],
    image: "vz_result.png",
  },
  testimonial: {
    content: `The team’s attention to detail and commitment to our vision were
                exceptional. The app exceeded our expectations in design and
                functionality.`,
    name: "Edward Johnson",
    company: "Business Bay",
  },
};

const MetaSpace = {
  section1: {
    title: "metaspace",
    tagLine: "Reality Reshaped",
    btnText: "Register Your Business",
    bannerImage: "ms_banner.png",
  },

  section2: {
    overview: `Metaspace is a simulated metaverse poised to transform the virtual landscape, offering users a multifaceted experience. Its expansive ecosystem provides diverse services, including an NFT marketplace, AAA game, virtual concerts, spiritual experiences, fashion shows, and virtual shopping.`,
    founded: "02 January 2025",
    industry: "Social Media",
    scope: "UI/UX Design, Brand Design, Development",
  },
  section3: {
    requirement: `Metaspace harnesses cutting-edge technology to deliver a blockchain-based NFT marketplace, offering users a seamless gateway to buy, sell, and trade in-metaverse assets. This expansive metaverse game is centered around an infinite space lifestyle, blending immersive gameplay with a vibrant virtual environment.`,
  },
  section4: {
    role: `Bastionex proudly contributes to the development of Metaspace. Our expertise in blockchain and metaverse technology has been pivotal in creating a dynamic and immersive digital universe. We have seamlessly integrated blockchain technology into Metaspace, ensuring a secure and transparent gaming and metaverse experience.`,
    roleImage: "ms_role.png",
    challenge: `In today’s digital age, social media platforms play a crucial
                  role in connecting people. Our client envisioned a unique app
                  that helps users achieve personal milestones through
                  gamification. The goal was to combine social interaction with
                  personal growth, allowing users to set goals, track progress,
                  and celebrate achievements in a fun and engaging way.`,
    challengeImage: "ms_challenge.png",
    profile: `In today’s digital age, social media platforms play a crucial
                  role in connecting people. Our client envisioned a unique app
                  that helps users achieve personal milestones through
                  gamification. The goal was to combine social interaction with
                  personal growth, allowing users to set goals, track progress,
                  and celebrate achievements in a fun and engaging way.`,
    profileImage: "ms_profile.png",
  },

  section7: {
    img1: "m1.png",
    img2: "m2.png",
    img3: "m3.png",
    img4: "m4.png",
    img5: "m5.png",
    img6: "m6.png",
    img7: "m7.png",
    img8: "m8.png",
    img9: "m9.png",
    img10: "m10.png",
    img11: "m11.png",
    img12: "m12.png",
    img13: "m13.png",
    img14: "m14.png",
    img15: "m15.png",
    img16: "m16.png",
    img17: "m17.png",
  },

  section11: {
    content: [
      { value: "10K+", title: "Downloads" },
      { value: "30K+", title: "Community Members" },
      { value: "1st", title: "Indian P2E with Story & Arena" },
    ],
    image: "ms_result.png",
  },
  testimonial: {
    content: `The team’s attention to detail and commitment to our vision were
            exceptional. The app exceeded our expectations in design and
            functionality.`,
    name: "Edward Johnson",
    company: "Business Bay",
  },
};

const Sheluxe = {
  section1: {
    title: "Sheluxe",
    tagLine: "Made for every She",
    btnText: "Get In Touch",
    bannerImage: "sh_banner.png",
  },

  section2: {
    overview: `Sheluxe redefines elegance with delicately crafted lingerie, designed to celebrate the beauty of every woman. Their commitment to quality and craftsmanship ensures unparalleled comfort and timeless allure.`,
    founded: "02 January 2025",
    industry: "Social Media",
    scope: "UI/UX Design, Brand Design, Development",
  },
  section3: {
    requirement: `Establishing brand recognition and trust is a challenge in the competitive e-commerce market. Building visibility in a crowded space demands consistent effort and strategic positioning. Overcoming these hurdles is key to creating a strong, trustworthy brand that resonates with consumers.`,
  },
  section4: {
    role: `Ensures consistent quality control and upholds luxury and comfort. Partnering with reputable manufacturers, and regular quality checks.`,
    roleImage: "sh_role.png",
    challenge: `In today’s digital age, social media platforms play a crucial role in connecting people. Our client envisioned a unique app that helps users achieve personal milestones through gamification. The goal was to combine social interaction with personal growth, allowing users to set goals, track progress, and celebrate achievements in a fun and engaging way.`,
    challengeImage: "sh_challenge.png",
    profile: `In today’s digital age, social media platforms play a crucial role in connecting people. Our client envisioned a unique app that helps users achieve personal milestones through gamification. The goal was to combine social interaction with personal growth, allowing users to set goals, track progress, and celebrate achievements in a fun and engaging way.`,
    profileImage: "sh_profile.png",
  },

  section7: {
    img1: "sh1.png",
    img2: "sh2.png",
    img3: "sh3.png",
    img4: "sh4.png",
    img5: "sh5.png",
    img6: "sh6.png",
    img7: "sh7.png",
    img8: "sh8.png",
    img9: "sh9.png",
    img10: "sh10.png",
    img11: "sh11.png",
    img12: "sh12.png",
    img13: "sh13.png",
    img14: "sh14.png",
    img15: "sh15.png",
    img16: "sh16.png",
    img17: "sh17.png",
  },

  section11: {
    content: [
      { value: "2K+", title: "Customers Global Shipping" },
      { value: "95%", title: "Customer Satisfaction" },
    
    ],
    image: "sh_result.png",
  },
  testimonial: {
    content: `The team’s attention to detail and commitment to our vision were
            exceptional. The app exceeded our expectations in design and
            functionality.`,
    name: "Edward Johnson",
    company: "Business Bay",
  },
};

const mondus = {
  section1: {
    title: "Mondus Properties",
    tagLine: "Crafting Seamless Paths to Your Dubai Property Dreams",
    btnText: "Get In Touch",
    bannerImage: "mn_banner.png",
  },

  section2: {
    overview: `Dubai-based real estate agency specializing in property buying, renting, and investment. They emphasize transparency, sustainability, and personalized service, aiming to redefine the real estate experience in Dubai.`,
    founded: "02 January 2025",
    industry: "Social Media",
    scope: "UI/UX Design, Brand Design, Development",
  },
  section3: {
    requirement: `To revamp the website with a modern design, seamless user experience, optimized navigation, and updated content to enhance client engagement and showcase their real estate services effectively.`,
  },
  section4: {
    role: `We helped redesign the website's interface, improving functionality, streamlining navigation, optimizing performance, and crafting engaging content to align with Mondus Properties' vision, enhancing user experience and client satisfaction.`,
    roleImage: "mn_role.png",
    challenge: `In today’s digital age, social media platforms play a crucial role in connecting people. Our client envisioned a unique app that helps users achieve personal milestones through gamification. The goal was to combine social interaction with personal growth, allowing users to set goals, track progress, and celebrate achievements in a fun and engaging way.`,
    challengeImage: "mn_challenge.png",
    profile: `In today’s digital age, social media platforms play a crucial role in connecting people. Our client envisioned a unique app that helps users achieve personal milestones through gamification. The goal was to combine social interaction with personal growth, allowing users to set goals, track progress, and celebrate achievements in a fun and engaging way.`,
    profileImage: "mn_profile.png",
  },

  section7: {
    img1: "mn (1).png",
    img2: "mn (2).png",
    img3: "mn (3).png",
    img4: "mn (4).png",
    img5: "mn (5).png",
    img6: "mn (6).png",
    img7: "mn (7).png",
    img8: "mn (8).png",
    img9: "mn (9).png",
    img10: "mn (10).png",
    img11: "mn (11).png",
    img12: "mn (12).png",
    img13: "mn (13).png",
    img14: "mn (14).png",
    img15: "mn (15).png",
    img16: "mn (16).png",
    img17: "mn (17).png",
  },

  section11: {
    content: [
      { value: "500K+", title: "properties Sold" },
      { value: "50+", title: "Eco-Friendly Projects" },
      { value: "4.4", title: "Customer Rating" },
      { value: "95%", title: "Client Satisfaction" },
    ],
    image: "mn_result.png",
  },
  testimonial: {
    content: `The team’s attention to detail and commitment to our vision were
            exceptional. The app exceeded our expectations in design and
            functionality.`,
    name: "Edward Johnson",
    company: "Mondus Properties",
  },
};

const gpm = {
  section1: {
    title: "Government Polytechnic Mumbai",
    tagLine: "Bridging Sustainability and Connectivity.",
    btnText: "Get In Touch",
    bannerImage: "gpm_banner.png",
  },

  section2: {
    overview: `Revolutionizing and empowering Connections with Next-Generation
                Digital Cards. BusinessBay specializes in premium, eco-friendly
                business cards crafted for sustainability and seamless digital
                integration. With BusinessBay, networking is smarter, greener,
                and more impactful.`,
    founded: "02 January 2025",
    industry: "Social Media",
    scope: "UI/UX Design, Brand Design, Development",
  },
  section3: {
    requirement: `BusinessBay envisions a website that goes beyond offering NFC
            business cards, serving as a comprehensive e-commerce solution for
            both B2B and B2C needs. With intuitive navigation and streamlined
            resume creation, the platform aims to deliver an all-in-one
            experience tailored for modern professionals.`,
  },
  section4: {
    role: `Developed a seamless, user-friendly platform with advanced 
    features to elevate your experience with BusinessBay. Designed by
     our expert team, the platform ensures effortless NFC card activation 
     and offers a comprehensive business solution tailored for success.`,
    roleImage: "gpm_role.png",
    challenge: `In today’s digital age, social media platforms play a crucial
                  role in connecting people. Our client envisioned a unique app
                  that helps users achieve personal milestones through
                  gamification. The goal was to combine social interaction with
                  personal growth, allowing users to set goals, track progress,
                  and celebrate achievements in a fun and engaging way.`,
    challengeImage: "gpm_challenge.png",
    profile: `In today’s digital age, social media platforms play a crucial
                  role in connecting people. Our client envisioned a unique app
                  that helps users achieve personal milestones through
                  gamification. The goal was to combine social interaction with
                  personal growth, allowing users to set goals, track progress,
                  and celebrate achievements in a fun and engaging way.`,
    profileImage: "gpm_profile.png",
  },

  section7: {
    img1: "gpm1.png",
    img2: "gpm2.png",
    img3: "gpm3.png",
    img4: "gpm4.png",
    img5: "gpm5.png",
    img6: "gpm6.png",
    img7: "gpm7.png",
    img8: "gpm8.png",
    img9: "gpm9.png",
    img10: "gpm10.png",
    img11: "gpm11.png",
    img12: "gpm12.png",
    img13: "gpm13.png",
    img14: "gpm14.png",
    img15: "gpm15.png",
    img16: "gpm16.png",
    img17: "gpm17.png",
  },

  section11: {
    content: [
      { value: "500K+", title: "Active Users" },
      { value: "--", title: "Global Reach" },
      { value: "--", title: "Sustainable Practices" },
    ],
    image: "gpm_result.png",
  },
  testimonial: {
    content: `The team’s attention to detail and commitment to our vision were
            exceptional. The app exceeded our expectations in design and
            functionality.`,
    name: "Edward Johnson",
    company: "Business Bay",
  },
};


export const CaseStudy = () => {
  const { company } = useParams();
  const [data, setData] = useState(businessBay);

  useEffect(() => {
    if (company === "vuezen") {
      return setData(vuezen);
    }
    if (company === "BusinessBay") {
      return setData(businessBay);
    }
    if (company === "MetaSpace") {
      return setData(MetaSpace);
    }
    if (company === "sheluxe") {
      return setData(Sheluxe);
    }
    if (company === "mondus-properties"){
      return setData(mondus);
    }
    if (company === "GPM"){
      return setData(gpm);
    }
  }, [company]);

  return (
    <CaseStudyWrapper>
      <AllMainSec1>
        <MainSec1>
          <LeftSec1>
            <LeftSec1H2>{data?.section1?.title}</LeftSec1H2>
            <LeftSec1P>{data?.section1?.tagLine}</LeftSec1P>
            <GetBtnSec1>
              <GetBtnSec1Button>{data?.section1?.btnText}</GetBtnSec1Button>
            </GetBtnSec1>
          </LeftSec1>

          <RightSec1>
            <RightSec1Img
              src={`/images/${data?.section1?.bannerImage}`}
              alt="Section 1 Image"
            />
          </RightSec1>
        </MainSec1>
      </AllMainSec1>

      <AllMainSec2>
        <MainSec2>
          <LeftSec2>
            <MainHeadSec2>
              <h2>Overview</h2>
              <p>{data?.section2?.overview}</p>
            </MainHeadSec2>

            <RightSec2>
              <HeadSec2Below>
                <h2>Founded</h2>
                <h4>{data?.section2?.founded}</h4>
              </HeadSec2Below>
              <HeadSec2Below>
                <h2>Industry</h2>
                <h4>{data?.section2?.industry}</h4>
              </HeadSec2Below>
              <HeadSec2Below>
                <h2>Scope of Work</h2>
                <h4>{data?.section2?.scope}</h4>
              </HeadSec2Below>
            </RightSec2>
            <Framework>Development Framework</Framework>
            <RightSec2>
              <HeadSec2Below>
                <h2>Frontend</h2>
                <h4>ReactJS</h4>
              </HeadSec2Below>
              <HeadSec2Below>
                <h2>Backend</h2>
                <h4>NodeJS</h4>
              </HeadSec2Below>
              <HeadSec2Below>
                <h2>Database</h2>
                <h4>MySQL</h4>
              </HeadSec2Below>
              <HeadSec2Below>
                <h2>Server</h2>
                <h4>Apache</h4>
              </HeadSec2Below>
              <HeadSec2Below>
                <h2>Editors</h2>
                <h4>VS Code</h4>
              </HeadSec2Below>
            </RightSec2>
          </LeftSec2>

          <RightSection2 src="/images/bb_cs_process.png"></RightSection2>
        </MainSec2>
      </AllMainSec2>

      <Requirements>
        <MainHeadSec2>
          <h2>Requirements</h2>
          <p>{data?.section3?.requirement}</p>
        </MainHeadSec2>
      </Requirements>

      <AllMainSec6>
        <MainSec6>
          <LeftSec6>
            <img src="/images/cs-ring.png" alt="Section 6 Left" />
          </LeftSec6>
          <CenterSec6>
            <MainHeadSec2>
              <h2>Role</h2>
              <p>{data?.section4?.role}</p>
            </MainHeadSec2>
          </CenterSec6>
          <RightSec6>
            <img
              src={`/images/${data?.section4?.roleImage}`}
              alt="Section 6 Right"
            />
          </RightSec6>
        </MainSec6>
      </AllMainSec6>

      <AllMainSec5>
        <MainSec5>
          <Sec5Content>
            <LeftSec5Content>
              <Sec5Img1>
                <img src="/images/sec5img1.png" alt="Section 5 Image 1" />
              </Sec5Img1>
              <MainHeadSec2>
                {" "}
                <h2>Project Challenges</h2>
                <h4>User Experience</h4>
                <p>{data?.section4?.challenge}</p>
              </MainHeadSec2>
            </LeftSec5Content>
            <RightSec5Content>
              <img
                src={`/images/${data?.section4?.challengeImage}`}
                alt="Section 5 Phone 1"
              />
            </RightSec5Content>
          </Sec5Content>

          <Sec5Content second>
            <Sec5Img2>
              <img src="/images/sec5img2.png" alt="Section 5 Image 2" />
            </Sec5Img2>
            <RightSec5Content>
              <img
                src={`/images/${data?.section4?.profileImage}`}
                alt="Section 5 Phone 2"
              />
            </RightSec5Content>
            <LeftSec5Content>
              <MainHeadSec2>
                {" "}
                <h2>Complex Profile Showcase</h2>
                <p>{data?.section4?.profile} </p>
              </MainHeadSec2>
            </LeftSec5Content>
          </Sec5Content>
        </MainSec5>
      </AllMainSec5>

      <AllMainSec7>
        <MainSec7>
          <AllImagesSec7>
            <div>
              <Row className="row1sec7">
                <img
                  src={`/images/${data?.section7?.img1}`}
                  alt="Row1Image 2"
                />
                <img
                  src={`/images/${data?.section7?.img2}`}
                  alt="Row3Image 2"
                />
              </Row>
              <Row className="row1sec7">
                <img
                  src={`/images/${data?.section7?.img3}`}
                  alt="Row1Image 2"
                />
                <img
                  src={`/images/${data?.section7?.img4}`}
                  alt="Row3Image 2"
                />
              </Row>
            </div>

            <div>
              <Row className="row2sec7">
                <img
                  src={`/images/${data?.section7?.img5}`}
                  alt="Row1Image 2"
                />
                <img
                  src={`/images/${data?.section7?.img6}`}
                  alt="Row3Image 2"
                />
              </Row>
              <Row className="row2sec7">
                <img
                  src={`/images/${data?.section7?.img7}`}
                  alt="Row1Image 2"
                />
                <img
                  src={`/images/${data?.section7?.img8}`}
                  alt="Row3Image 2"
                />
              </Row>
            </div>

            <div>
              <Row className="row3sec7">
                <img
                  src={`/images/${data?.section7?.img9}`}
                  alt="Row1Image 2"
                />
                <img
                  src={`/images/${data?.section7?.img10}`}
                  alt="Row3Image 2"
                />
              </Row>
              <Row className="row3sec7">
                <img
                  src={`/images/${data?.section7?.img11}`}
                  alt="Row1Image 2"
                />
                <img
                  src={`/images/${data?.section7?.img12}`}
                  alt="Row3Image 2"
                />
              </Row>
            </div>

            <div>
              <Row className="row4sec7">
                <img
                  src={`/images/${data?.section7?.img13}`}
                  alt="Row1Image 2"
                />
                <img
                  src={`/images/${data?.section7?.img14}`}
                  alt="Row3Image 2"
                />
              </Row>
              <Row className="row4sec7">
                <img
                  src={`/images/${data?.section7?.img15}`}
                  alt="Row1Image 2"
                />
                <img
                  src={`/images/${data?.section7?.img16}`}
                  alt="Row3Image 2"
                />
              </Row>
            </div>

            <div>
              <Row className="row3sec7">
                <img
                  src={`/images/${data?.section7?.img17}`}
                  alt="Row1Image 2"
                />
                <img
                  src={`/images/${data?.section7?.img11}`}
                  alt="Row3Image 2"
                />
              </Row>
              <Row className="row3sec7">
                <img
                  src={`/images/${data?.section7?.img2}`}
                  alt="Row1Image 2"
                />
                <img
                  src={`/images/${data?.section7?.img3}`}
                  alt="Row3Image 2"
                />
              </Row>
            </div>
          </AllImagesSec7>
        </MainSec7>
      </AllMainSec7>

      <AllMainSec11>
        <MainSec11>
          <LeftSec11>
            <MainHeadSec2>
              {" "}
              <h2>Results & Outcomes</h2>
            </MainHeadSec2>
            <p>The application achieved the following outcomes</p>
            <OutcomeSec11>
              {data?.section11?.content?.map((item) => (
                <OutcomeSec>
                  <h4> {item?.value}</h4>
                  <p>{item?.title}</p>
                </OutcomeSec>
              ))}
              {/* <OutcomeSec>
                <h4> {data?.section11?.outcome1?.value}</h4>
                <p>{data?.section11?.outcome1?.title}</p>
              </OutcomeSec>
              <OutcomeSec2>
                <h4>{data?.section11?.outcome2?.value}</h4>
                <p>G{data?.section11?.outcome2?.title}</p>
              </OutcomeSec2>
              <OutcomeSec>
                <h4>
                  -- <span></span>{" "}
                </h4>
                <p>Sustainable Practices</p>
              </OutcomeSec> */}
            </OutcomeSec11>
          </LeftSec11>
          <RightSec11>
            <img
              src={`/images/${data?.section11?.image}`}
              alt="Results and Outcomes"
            />
          </RightSec11>
        </MainSec11>
      </AllMainSec11>

      <AllMainSec12>
        <MainSec12>
          <p>{data?.testimonial?.content}</p>
          <h4>{data?.testimonial?.name}</h4>
          <span>{data?.testimonial?.company}</span>
        </MainSec12>
      </AllMainSec12>

      <AllMainSec13>
        <MainSec13>
          <MainHeadSec2 Conclusion>
            <h2>Conclusion</h2>
            <p>
              This project highlights our expertise in creating user-centered
              designs that drive engagement and deliver measurable results. Got
              a project in mind? Let’s collaborate to turn your ideas into
              reality.
            </p>
          </MainHeadSec2>

          <GetInTouchBtn>
            <button>Get In Touch</button>
          </GetInTouchBtn>
        </MainSec13>
      </AllMainSec13>
    </CaseStudyWrapper>
  );
};
