// import styled from 'styled-components';
// import Logo1 from '../../Component/Images/logo1-bastionex.png';
// import Logo2 from '../../Component/Images/logo2-shereal.png';
// // import Logo3 from '../../Component/Images/logo3-BastionEx.png';
// import Logo4 from '../../Component/Images/logo4-meta.png';
// import Logo5 from '../../Component/Images/logo5-collexion.png';
// import CurveImage from '../../Component/Images/curve.png';

// const Image = styled.img`
// margin-right:20px;
// @media(max-width:840px){
//   max-width:90px;
//   margin-top: 20px;

// }
// @media(max-width:640px){
//   max-width:60px;
//   margin-top: 20px;
// }
// `;

// const Root = styled.div`
// width:100%;
// background-color:#cfeee6;
// display:flex;
// align-items:center;
// justify-content: center;
// position: relative;
// `;
// const CardWrapper = styled.div`
// padding:30px 70px;
// min-height:170px !important;
// box-shadow: 0px 0px 15px 1px rgb(0 0 0 / 23%);
// border-radius:6px;
// width:900px;
// // margin-top: -80px;
// margin-top:45px;
// display: flex;
// align-items:center;
// flex-direction:column;
// background-color: #fff;
// position:relative;
// z-index:10;
// @media(max-width:1024px){
//   width:100%;
//   margin: 40px 30px 0 30px;
//   padding:30px 50px;
// }
// /* @media(max-width:960px){
//   width:100%;
//   margin:-20px 30px 0 30px;
//   padding:30px 50px;
// } */
// @media(max-width:767px){
//   width:100%;
//   margin:-80px 30px 0 30px;
//   padding:30px 20px;
// }
// `;
// const ImageWrraper = styled.div`
// display:flex;
// align-items:center;
// justify-content:space-between;
// width: 100%;
// /* margin-top: 20px; */
// margin: -30px 0px -25px 0px;
// flex-wrap:wrap;
// @media(max-width:767px){
//   justify-content:center;
//   margin: 1px;
// }
// `;
// const Text = styled.div`
// font-size:18px;
// font-weight:500;
// color:#686868;
// text-align: center;
// `;
// const BgImage = styled.img`
// position: absolute;
// // top:-55px;
// top: 0;
// left:0;
// z-index:1;
// width: 100%;
// @media(max-width:767px){
//    top:-50px;
// }
// @media(max-width:581px){
//    top:-30px;
// }

// `;
// function BelivingCompanies() {
//   return (
//     <Root>
//       {/* <BgImage src={CurveImage}/> */}
//       <CardWrapper>
//         <Text>World's top-class companies believing us!</Text>
//          <ImageWrraper>
//           <Image src={Logo1} alt="Bastionex Infotech Project" />
//           <Image src={Logo2} alt="Bastionex Infotech Project" />
//           {/* <Image src={Logo3}/> */}
//           <Image src={Logo4} alt="Bastionex Infotech Project" />
//           <Image src={Logo5} alt="Bastionex Infotech Project" />
//           </ImageWrraper>
//       </CardWrapper >
//     </Root>
//   );
// }
// export default BelivingCompanies;


import React from "react";
import styled from "styled-components";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import Logo1 from "../../Component/Images/ms-logo.png";
import Logo2 from "../../Component/Images/gpm-logo.png";
import Logo3 from "../../Component/Images/bb-logo.png";
import Logo4 from "../../Component/Images/vuezen-logo.png";
import Logo5 from "../../Component/Images/sheluxe-logo.png";
import Logo6 from "../../Component/Images/shereal-logo.png";
import Logo7 from "../../Component/Images/colexion-logo.png";
import Logo8 from "../../Component/Images/mondus-logo.png";
import Logo9 from "../../Component/Images/floxytravel.png"
import './BelivingCompanies.css';

const Root = styled.div`
  width: 100%;
  background-color: #fafbff;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
`;

const CardWrapper = styled.div`
font-family: Instrument Sans;
  margin-top:-5% !important;
  padding: 20px 70px;
  min-height: 170px !important;
  box-shadow: 0px 0px 15px 1px rgb(0 0 0 / 23%);
  border-radius: 6px;
  
  width: 80%;
  margin-top: 45px;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: #fff;
  position: relative;
  z-index: 10;

    @media (min-width: 2560px) {
  // width:91%;
    margin-top: -4% !important;
  }
    @media (min-width: 2500px) {
  // width:95%;
    margin-top: -4% !important;
  }
    
   @media (min-width: 2300px) {
  // width:91%;
    margin-top: -4% !important;
  }
    @media (min-width: 2200px) {
  // width:91%;
    margin-top: -4% !important;
  }

   @media (max-width: 1500px) {
  width:91%;
    margin-top: -7% !important;
  }
    @media (max-width: 1350px) {
  width:91%;
    margin-top: -8% !important;
  }
  @media (max-width: 1024px) {
    width: 91%;
    margin: 40px 30px 0 30px;
    padding: 30px 50px;
    margin-top:-11% !important;
  }
  @media (max-width: 768px) {
    width: 91%;
    margin: -80px 30px 0 30px;
    padding: 30px 20px;
    margin-top: -12% !important;
  }
  @media (max-width: 700px) {
    margin-top: -15% !important;
  }
  @media (max-width: 600px) {
    margin-top: -17% !important;
    
    }
     

   @media(max-width:426px){
      margin-top:-26% !important;
      }
    
`;

const Text = styled.div`
  font-size: 18px;
  font-weight: 500;
  color: #686868;
  text-align: center;
  margin-bottom: 20px;
`;

const ImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 150px;
  height: 100px; /* Adjust as needed */
  overflow: hidden;
  margin: auto;
`;

const Image = styled.img`
  max-width: 100%;
  // max-height: 100%;
  object-fit: contain; /* Ensures the image maintains aspect ratio */
`;

function BelievingCompanies() {
  const logos = [
    Logo1,
    Logo2,
    Logo3,
    Logo4,
    Logo5,
    Logo6,
    Logo7,
    Logo8,
    // Logo9

  ];

  const options = {
    loop: true,
    margin: 10,
    nav: false,
    dots: false,
    autoplay: true,
    autoplayTimeout: 3000,
    autoplayHoverPause: false,
    responsive: {
      0: {
        items: 2,
      },
      480: {
        items: 3,
      },
      768: {
        items: 5,
      },
      1024: {
        items: 6,
      },
    },
  };

  return (
    <Root>
      <CardWrapper>
        <Text>World's top-class companies believing us!</Text>
        <OwlCarousel className="owl-theme" {...options}>
          {logos.map((logo, index) => (
            <div key={index} className="item">
              <ImageWrapper >
                <Image  src={logo} alt={`Logo ${index + 1}`} />
              </ImageWrapper>
            </div>
          ))}
        </OwlCarousel>
      </CardWrapper>
    </Root>
  );
}

export default BelievingCompanies;