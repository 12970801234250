// Styled.js
import styled from 'styled-components';

export const HeroContainer = styled.div`
  background: linear-gradient(135deg, #1a1a3c 0%, #2d1b4e 100%);
  min-height: 400px;
//   width: 100%;
  padding: 60px 20px;
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
`;

export const ContentWrapper = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 40px;

  @media (max-width: 768px) {
    flex-direction: column;
    text-align: center;
  }
`;

export const TextContent = styled.div`
  flex: 1;
  color: white;
`;

export const Title = styled.h1`
  font-family: Instrument Sans, sans-serif;
  font-size: 2.5rem;
  font-weight: 700;
  margin-bottom: 20px;
  line-height: 1.2;
  
  @media (max-width: 768px) {
    font-size: 2rem;
  }
`;

export const Subtitle = styled.p`
  font-family: Instrument Sans, sans-serif;
  font-size: 20px;
  line-height: 1.6;
  opacity: 0.9;
  max-width: 600px;
`;

export const ImageWrapper = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  
  img {
    max-width: 100%;
    height: auto;
  }

  @keyframes float {
    0%, 100% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-20px);
    }
  }
`;


// Main container
export const ServicesContainer = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 40px 20px;
`;

// Section Header ("WHAT WE DO")
export const SectionHeader = styled.div`
  text-align: left;
  margin-bottom: 20px;
  color:#000;

  h4 {
    font-family: Instrument Sans, sans-serif;
    font-size: 1rem;
    font-weight: bold;
    color: #7a7a7a;
    text-transform: uppercase;
    margin-bottom: 5px;
  }

  hr {
    border: none;
    height: 2px;
    background-color: #e0e0e0ad;
    margin: 0;
  }
`;

// Title and stats container
export const TitleStatsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  gap: 20px;
  margin-bottom: 20px;

  @media (min-width: 768px) {
    flex-direction: row;
    align-items: center;
    gap: 0;
  }

  @media (min-width: 1024px) {
    margin-bottom: 40px;
  }
`;

// Title
export const Title2 = styled.h2`
  font-family: Instrument Sans, sans-serif;
  font-size: 1.8rem;
  font-weight: bold;
  color: #4453f6;
  text-align: left;
  margin: 0;

  @media (min-width: 768px) {
    font-size: 2rem;
  }

  @media (min-width: 1024px) {
    font-size: 2.5rem;
  }
`;

// Stats container
export const StatsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: flex-start;

  @media (min-width: 768px) {
    justify-content: flex-end;
  }

  div {
    display: flex;
    align-items: center;
    justify-content: center;
    // text-align: center;

    @media (max-width: 768px) {
      display: flex;
      align-items: center;
      flex-direction: column;
    }

    h3 {
      font-family: Instrument Sans, sans-serif;
      font-size: 1.5rem;
      color: #47c052;
      margin: 0;

      @media (min-width: 768px) {
        font-size: 3rem;
      }
    }

    p {
     font-family: Instrument Sans, sans-serif;
     font-size: 12px;
     font-weight: 500;
     line-height: 16px;
     text-align: left;
      // font-size: 0.9rem;
      color: #A3A7B6;
      margin: 0;

      // @media (min-width: 768px) {
      //   font-size: 1rem;
      // }
    }
  }
`;

// Grid container for services
export const ServicesGrid = styled.div`
  display: grid;
  gap: 20px;
  grid-template-columns: repeat(1, 1fr);

  @media (min-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (min-width: 1024px) {
    grid-template-columns: repeat(3, 1fr);
  }
`;

// Service card
export const ServiceCard = styled.div`
  overflow: hidden;
  text-align: left;

  img {
    width: 100%;
    height: auto;
    object-fit: cover;
  }

  div {
    margin-top: -20px;
    padding: 20px;

    h3 {
      font-family: Instrument Sans, sans-serif;
      font-size: 1rem;
      color: #4a4a4a;
      margin-bottom: -10px;

      @media (min-width: 768px) {
        font-size: 1.2rem;
      }
    }

    p {
      font-family: Instrument Sans, sans-serif;
      font-size: 0.9rem;
      color: #A3A7B6;
      margin-bottom: 3px;

      @media (min-width: 768px) {
        font-size: 1rem;
      }
    }

    a {
      font-family: Instrument Sans, sans-serif;
      font-size: 0.9rem;
      color: #1A1A1A;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }

      @media (min-width: 768px) {
        font-size: 1rem;
      }
    }
  }
`;












export const SectionContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  min-height: 300px;
  background: linear-gradient(to right, #2d2d2d, #3d3d3d);

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const ContentWrapper2 = styled.div`
  flex: 1;
  padding: 20px;
  text-align: center;
  max-width: 50%;

  @media (max-width: 1200px) {
    max-width: 70%;
  }

  @media (max-width: 768px) {
    padding: 10px;
    max-width: 100%;
  }
`;

export const Title3 = styled.h1`
  font-family: Instrument Sans, sans-serif;
  color: #fff;
  font-size: 3.25rem;
  margin-bottom: 10px;

  @media (max-width: 1200px) {
    font-size: 2.5rem;
  }

  @media (max-width: 768px) {
    font-size: 1.8rem;
  }

  @media (max-width: 480px) {
    font-size: 1.5rem;
  }
`;

export const Description = styled.p`
  font-family: Instrument Sans, sans-serif;
  color: #fff;
  font-size: 20px;
  margin-bottom: 20px;

  @media (max-width: 1200px) {
    font-size: 1rem;
  }

  @media (max-width: 768px) {
    font-size: 0.875rem;
  }

  @media (max-width: 480px) {
    font-size: 0.75rem;
  }
`;

export const ContactButton = styled.button`
  font-family: Instrument Sans, sans-serif;
  padding: 10px 20px;
  border-radius: 100px;
  font-size: 18px;
  cursor: pointer;
  border: none;
  color: #1A1A1A;
  background-color: #fff;

  @media (max-width: 1200px) {
    font-size: 0.9rem;
    padding: 8px 18px;
  }

  @media (max-width: 768px) {
    font-size: 0.8rem;
    padding: 6px 14px;
  }
`;

export const LeftImage = styled.img`
  width: 300px;
  height: 300px;

  @media (max-width: 1200px) {
    width: 293px;
  }

  @media (max-width: 768px) {
    width: 100%;
    // max-width: 409px;
  }
`;

export const RightImage = styled.img`
  width: 300px;
  height: 300px;

  @media (max-width: 1200px) {
    width: 293px;
  }

  @media (max-width: 768px) {
    width: 100%;
    // max-width: 409px;

  }
`;

