import React from 'react'
import './Industries.css'
import drop from '../Images/industry-drop.webp'

export const Industries = () => {
  return (
    <div className='industries-div'>
<div className="d_flex">
   {/* <div className="menu__col menu__cta__wrapper dflex">
      <div className="lg_text_wrapper">
         <span>Diverse Capabilities</span>
         <p className="para">that deploy customized solutions in a wide range of
            industries
         </p>
      </div>
      <div className="industry_left_img">
         <div className="imag_panel"> <a href="#" className=""><img src={drop} alt="Supply Chain Industry boom in India"/> </a></div>
         <div className="bottom-text space-between">
            <div className="read_our"> Read our developments that has helped the <a  className="">Supply Chain</a> Industry boom in India</div>
            <div className="cirl_arw"> <a href="/" className=""><i className="fa-solid fa-chevron-right"></i></a></div>
         </div>
      </div>
   </div> */}
   <div className="menu__col">
      <div className="cl-inner">
         <div className="left_icon_panel">
            <figure> <img src="/IndustriesIcon/on-demand-icn.svg" alt="On demand App development"/></figure>
         </div>
         <div className="right_text_panel">
            <div className="heading4"> <a href="/" className="">On Demand<span></span></a></div>
         </div>
      </div>
      <div className="cl-inner">
         <div className="left_icon_panel">
            <figure> <img src="/IndustriesIcon/healthcare-icn.svg" alt="Healthcare Software development Services"/></figure>
         </div>
         <div className="right_text_panel">
            <div className="heading4"> <a href="/" className="">Healthcare<span></span></a></div>
         </div>
      </div>
      <div className="cl-inner">
         <div className="left_icon_panel">
            <figure> <img src="/IndustriesIcon/restaurants-icn.svg" alt="Restaurant Software development Services"/></figure>
         </div>
         <div className="right_text_panel">
            <div className="heading4"> <a href="/" className="">Restaurant<span></span></a></div>
         </div>
      </div>
      <div className="cl-inner">
         <div className="left_icon_panel">
            <figure> <img src="/IndustriesIcon/entertainment-icn.svg" alt="entertainment Software development Services"/></figure>
         </div>
         <div className="right_text_panel">
            <div className="heading4"> <a href="/" className="">Entertainment<span></span></a></div>
         </div>
      </div>
      <div className="cl-inner">
         <div className="left_icon_panel">
            <figure> <img src="/IndustriesIcon/travel-icn.svg" alt="Travel App development Services"/></figure>
         </div>
         <div className="right_text_panel">
            <div className="heading4"> <a href="/" className="">Travel<span></span></a></div>
         </div>
      </div>
      <div className="cl-inner">
         <div className="left_icon_panel">
            <figure> <img src="/IndustriesIcon/e-scooter-icn.svg" alt="E-scooter App development Services"/></figure>
         </div>
         <div className="right_text_panel">
            <div className="heading4"> <a href="/" className="">E-scooter<span></span></a></div>
         </div>
      </div>
      <div className="cl-inner">
         <div className="left_icon_panel">
            <figure> <img src="/IndustriesIcon/events-icn.svg" alt="Events app development Services"/></figure>
         </div>
         <div className="right_text_panel">
            <div className="heading4"> <a href="/" className="">Events<span></span></a></div>
         </div>
      </div>
     
   </div>
   <div className="menu__col">
      <div className="cl-inner">
         <div className="left_icon_panel">
            <figure> <img src="/IndustriesIcon/mCommerce-icn.svg" alt="eCommerce Software development Services"/></figure>
         </div>
         <div className="right_text_panel">
            <div className="heading4"> <a href="/" className="">eCommerce<span></span></a></div>
         </div>
      </div>
      <div className="cl-inner">
         <div className="left_icon_panel">
            <figure> <img src="/IndustriesIcon/SaaS-icn.svg" alt="SaaS app development Services"/></figure>
         </div>
         <div className="right_text_panel">
            <div className="heading4"> <a href="/" className="">SaaS<span></span></a></div>
         </div>
      </div>
      <div className="cl-inner">
         <div className="left_icon_panel">
            <figure> <img src="/IndustriesIcon/sports-icn.svg" alt="mobile Games app development Services"/></figure>
         </div>
         <div className="right_text_panel">
            <div className="heading4"> <a href="/" className="">Games<span></span></a></div>
         </div>
      </div>
      <div className="cl-inner">
         <div className="left_icon_panel">
            <figure> <img src="/IndustriesIcon/wellness-icn.svg" alt="Wellness Software development Services"/></figure>
         </div>
         <div className="right_text_panel">
            <div className="heading4"> <a href="/" className="">Wellness<span></span></a></div>
         </div>
      </div>
      <div className="cl-inner">
         <div className="left_icon_panel">
            <figure> <img src="/IndustriesIcon/finance-icn.svg" alt="Finance Software development Services"/></figure>
         </div>
         <div className="right_text_panel">
            <div className="heading4"> <a href="/" className="">Finance<span></span></a></div>
         </div>
      </div>
      <div className="cl-inner">
         <div className="left_icon_panel">
            <figure> <img src="/IndustriesIcon/politics-icn.svg" alt="Politics Software development Services"/></figure>
         </div>
         <div className="right_text_panel">
            <div className="heading4"> <a href="/" className="">Politics<span></span></a></div>
         </div>
      </div>
      <div className="cl-inner">
         <div className="left_icon_panel">
            <figure> <img src="/IndustriesIcon/social-media-icn.svg" alt="Social Networking Software development Services"/></figure>
         </div>
         <div className="right_text_panel">
            <div className="heading4"> <a href="/" className="">Social Networking<span></span></a></div>
         </div>
      </div>
     
   </div>
   <div className="menu__col">
      <div className="cl-inner">
         <div className="left_icon_panel">
            <figure> <img src="/IndustriesIcon/real-state-icn.svg" alt="Real Estate app development Services"/></figure>
         </div>
         <div className="right_text_panel">
            <div className="heading4"> <a href="/" className="">Real Estate<span></span></a></div>
         </div>
      </div>
      <div className="cl-inner">
         <div className="left_icon_panel">
            <figure> <img src="/IndustriesIcon/mLearning-icn.svg" alt="Education app development Services"/></figure>
         </div>
         <div className="right_text_panel">
            <div className="heading4"> <a href="/" className="">Education<span></span></a></div>
         </div>
      </div>
      <div className="cl-inner">
         <div className="left_icon_panel">
            <figure> <img src="/IndustriesIcon/news-icn.svg" alt="News app development Services"/></figure>
         </div>
         <div className="right_text_panel">
            <div className="heading4"> <a href="/" className="">News<span></span></a></div>
         </div>
      </div>
      <div className="cl-inner">
         <div className="left_icon_panel">
            <figure> <img src="/IndustriesIcon/logistics-menu-icn.svg" alt="Logistics Software development Services"/></figure>
         </div>
         <div className="right_text_panel">
            <div className="heading4"> <a href="/" className="">Logistics<span></span></a></div>
         </div>
      </div>
      <div className="cl-inner">
         <div className="left_icon_panel">
            <figure> <img src="/IndustriesIcon/aviation-icn.svg" alt="Aviation Software development Services"/></figure>
         </div>
         <div className="right_text_panel">
            <div className="heading4"> <a href="/" className="">Aviation<span></span></a></div>
         </div>
      </div>
      <div className="cl-inner">
         <div className="left_icon_panel">
            <figure> <img src="/IndustriesIcon/agricultural-icn.svg" alt="Agriculture Software development Services"/></figure>
         </div>
         <div className="right_text_panel">
            <div className="heading4"> <a href="/" className="">Agriculture<span></span></a></div>
         </div>
      </div>
      <div className="cl-inner">
         <div className="left_icon_panel">
            <figure> <img src="/IndustriesIcon/ev-icn.svg" alt="electric vehicle software development"/></figure>
         </div>
         <div className="right_text_panel">
            <div className="heading4"> <a href="/" className="">EV<span></span></a></div>
         </div>
      </div>
      
   </div>
   <div className="menu__col">

<div className="cl-inner">
         <div className="left_icon_panel">
            <figure> <img src="/IndustriesIcon/automotive.svg" alt="Automotive Software development Services"/></figure>
         </div>
         <div className="right_text_panel">
            <div className="heading4"> <a href="/" className="">Automotive<span></span></a></div>
         </div>
      </div>
      <div className="cl-inner">
         <div className="left_icon_panel">
            <figure> <img src="/IndustriesIcon/manufacturing-head-icon.svg" alt="Manufacturing software development"/></figure>
         </div>
         <div className="right_text_panel">
            <div className="heading4"> <a href="/" className="">Manufacturing<span></span></a></div>
         </div>
      </div>
      <div className="cl-inner">
         <div className="left_icon_panel">
            <figure> <img src="/IndustriesIcon/banks.svg" alt="Banking Software development Services"/></figure>
         </div>
         <div className="right_text_panel">
            <div className="heading4"> <a href="/" className="">Banking<span></span></a></div>
         </div>
      </div>
      <div className="cl-inner">
         <div className="left_icon_panel">
            <figure> <img src="/IndustriesIcon/insurance-head-icon.svg" alt="Insurance software development"/></figure>
         </div>
         <div className="right_text_panel">
            <div className="heading4"> <a href="/" className="">Insurance<span></span></a></div>
         </div>
      </div>
      <div className="cl-inner">
         <div className="left_icon_panel">
            <figure> <img src="/IndustriesIcon/retail-head.svg" alt="Retail Software Development Services"/></figure>
         </div>
         <div className="right_text_panel">
            <div className="heading4"> <a href="/" className="">Retail </a></div>
         </div>
      </div>
      <div className="cl-inner">
         <div className="left_icon_panel">
            <figure> <img src="/IndustriesIcon/oil-and-gas.svg" alt="Oil and Gas Software development Services"/></figure>
         </div>
         <div className="right_text_panel">
            <div className="heading4"> <a href="/" className="">Oil and Gas<span></span></a></div>
         </div>
      </div>
      <div className="cl-inner">
         <div className="left_icon_panel">
            <figure> <img src="/IndustriesIcon/telecom-head-icon.svg" alt="Telecom software development"/></figure>
         </div>
         <div className="right_text_panel">
            <div className="heading4"> <a href="/" className="">Telecom<span></span></a></div>
         </div>
      </div>
      <div className="cl-inner">
         <div className="left_icon_panel">
            <figure> <img src="/IndustriesIcon/construction-head-icon.svg" alt="Construction Software Development Services"/></figure>
         </div>
         <div className="right_text_panel">
            <div className="heading4"> <a href="/" className="">Construction<span></span></a></div>
         </div>
      </div>
</div>
</div>




    </div>
  )
}
