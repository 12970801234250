import React from 'react'
import {
    HeroContainer,
    ContentWrapper,
    TextContent,
    Title,
    Subtitle,
    ImageWrapper,
    ServicesContainer,
    SectionHeader,
    TitleStatsContainer,
    Title2,
    StatsContainer,
    ServicesGrid,
    ServiceCard,
    SectionContainer,
    ContentWrapper2,
    Title3,
    Description,
    ContactButton,
    LeftImage,
    RightImage
  } from './MainServiceStyled.js';
  import banner from '../Images/bannerimage.png';
  import leftimg from '../Images/leftimg.png'
  import rightimg from '../Images/rightimg.png'
  import img1 from "../Images/Frame 1410089531.png";
  import img2 from "../Images/Frame2.png";
  import img3 from "../Images/Frame3.png";
  import img4 from "../Images/Frame4.png";
  import img5 from "../Images/Frame5.png";
  import img6 from "../Images/Frame6.png";
  import img7 from "../Images/Frame7.png";
  import img8 from "../Images/Frame8.png";
  import img9 from "../Images/Frame9.png";

export const MainServicePage = () => {

    const services = [
        { title: "Ideation and Product Design", img: img1 },
        { title: "Mobile App Development", img: img2 },
        { title: "Artificial Intelligence", img: img3 },
        { title: "Software Development", img: img4 },
        { title: "Cloud Services", img: img5 },
        { title: "IT Consulting", img: img6 },
        { title: "Blockchain Services", img: img7 },
        { title: "Analytics", img: img8 },
        { title: "Devops", img: img9 },
      ];

  return (
    <div>
 <HeroContainer>
      <ContentWrapper>
        <TextContent>
          <Title>
            Your Dreams<br />
            Inspire Us To Create<br />
            Possibilities
          </Title>
          <Subtitle>
            We are dedicated to transforming your unique vision into reality, 
            combining innovation, creativity, and excellence to exceed your expectations.
          </Subtitle>
        </TextContent>
        <ImageWrapper>
          <img 
            src={banner} 
            alt="Development visualization" 
          />
        </ImageWrapper>
      </ContentWrapper>
    </HeroContainer>




    <ServicesContainer>
      {/* Section Header */}
      <SectionHeader>
        <h4>WHAT WE DO</h4>
        <hr />
      </SectionHeader>

      {/* Title and Stats in a single row */}
      <TitleStatsContainer>
        <Title2>Innovative Digital<br /> Solution Services</Title2>
        <StatsContainer>
          <div>
            <h3 >25+</h3>
            <p>YEARS<br /> EXPERIENCE<br /> WORKING</p>
          </div>
          <div>
            <h3>100%</h3>
            <p>HAPPY &<br /> SATISFIED<br /> CLIENTS</p>
          </div>
        </StatsContainer>
      </TitleStatsContainer>

      {/* Services Grid */}
      <ServicesGrid>
        {services.map((service, index) => (
          <ServiceCard key={index}>
            <img src={service.img} alt={service.title} />
            <div>
              <h3>{service.title}</h3>
              <p>Generating creative ideas and crafting user-centric, functional product solutions.</p>
              <a href="#learn-more">Know more</a>
            </div>
          </ServiceCard>
        ))}
      </ServicesGrid>
    </ServicesContainer>



    <SectionContainer>
      <LeftImage src={leftimg} alt="Left Side Image" />
      <ContentWrapper2>
        <Title3>Speak to Our Experts</Title3>
        <Description>
          Stop worrying about problems. Focus on your business.<br />
          Let us provide the support you deserve
        </Description>
        <ContactButton>Contact Us</ContactButton>
      </ContentWrapper2>
      <RightImage src={rightimg} alt="Right Side Image" />
    </SectionContainer>


    </div>
  )
}
