import styled, { keyframes, css } from "styled-components";

export const CaseStudyWrapper = styled.div`
  font-family: DM Sans;
  background: #fff;
  overflow: hidden;
`;

export const customContainer = css`
  width: 80%;
  margin: 0 auto;
  @media (max-width:1100px){
  width:85%;
  }
  @media (max-width:576px){
  width:90%;
  }
`;

export const AllMainSec1 = styled.div`
  background: linear-gradient(45deg, #fff 46.44%, #95ccff 110.57%);
  color: white;
  padding-top: 120px;
  padding-bottom: 60px;
  // max-height:700px;
  height: 100%;

  @media (max-width: 1024px) {
    // padding: 90px 0px;
  }

  @media screen and (max-width: 768px) {
    padding:100px 0 50px 0px;
  }

  @media screen and (max-width: 480px) {
    // padding: 20px 0px;
  }
`;

export const MainSec1 = styled.div`
  height: 100%;

  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;

  margin: 0 auto;
  gap: 20px;

  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr;
    text-align: center;
  }
  ${customContainer}
`;

export const LeftSec1 = styled.div`
  text-align: center;
  // padding: 20px;

  @media screen and (max-width: 768px) {
    padding: 0px;
  }
`;

export const LeftSec1H2 = styled.h2`
  color: #000;
  text-transform: uppercase;
  font-size: 70px;
  margin: 0;
  margin-bottom: 5px;
  // line-height: 1.2;
  font-weight: 800;
    @media (max-width: 1024px) {
    font-size:60px;
  }

  @media screen and (max-width: 992px) {
    font-size: 56px;
  }


  @media screen and (max-width: 480px) {
     font-size: 45px;
  }
`;

export const LeftSec1P = styled.p`
  color: #808080;
  font-size: 20px;
  line-height: 1.6;
  margin: 0;
  margin-bottom: 30px;

  @media screen and (max-width: 768px) {
    font-size: 1rem;
  }

  @media screen and (max-width: 480px) {
    font-size: 0.9rem;
  }
`;

export const GetBtnSec1 = styled.div`
  margin-bottom: 20px;
`;

export const GetBtnSec1Button = styled.button`
  background: #f46d28;
  box-shadow: 0px 6.57px 21.91px 0px #f46d2866;
  color: #fff;
  border: none;
  padding: 15px 30px;
  border-radius: 4px;
  font-family: DM Sans;
  font-size: 14px;
  font-weight: 800;
  cursor: pointer;
  border: 2px solid transparent;
  // text-transform: uppercase;

  &:hover {
    background: rgb(255, 255, 255);
    border: 2px solid #f46d28;
    color: #f46d28;
  }
`;

export const AllThreeBtnSec1 = styled.div`
  padding-top: 23%;
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  justify-content: center;
`;

export const GetBtnSec1Below = styled.div`
  button {
    border: none;
    background: #494949;
    color: white;
    padding: 8px 16px;
    border-radius: 140px;
    font-size: 18px;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;

    &:hover {
      background-color: #1dbf73;
      color: white;
    }
  }
`;

export const RightSec1 = styled.div`
  text-align: center;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;

  @media (max-width:768px){
  justify-content: center;
  }

`;

export const RightSec1Img = styled.img`
  width: 100%;
  max-width: 700px;
  height: 100%;
  border-radius: 10px;

  @media screen and (max-width: 768px) {
    max-width: 300px;
  }

  @media screen and (max-width: 480px) {
    width: 100%;
  }
`;

export const AllMainSec2 = styled.div`
  padding: 35px 0px;
  background: #fff;

  @media (max-width: 1024px) {
    padding: 25px 0px;
  }

  @media (max-width: 768px) {
    padding: 20px 0px;
  }

  @media (max-width: 480px) {
    padding: 15px 0px;
  }
`;

export const MainHeadSec2 = styled.div`
  text-align: left;
  margin-bottom: 15px;

  h2 {
    font-size: 64px;
    font-weight: 800;
    margin: 0;
  }
  h4 {
    font-size: 30px;
    font-weight: 500;
    margin: 7px 0 0;
    color: #303030;
  }

  p {
    color: #303030;
    font-size: 18px;
    font-weight: 400;
  }

  @media (max-width: 1300px) {
   h2 {
    font-size: 58px;
     }
  h4 {
    font-size: 26px;
    }

  p {
       font-size: 16px;
     }
  }

  @media (max-width: 992px) {
     h2 {
    font-size: 50px;
     }
  h4 {
    font-size: 24px;
    }

  p {
       font-size: 16px;
     }
  }
    
  @media (max-width: 576px) {
     h2 {
    font-size: 40px;
    line-height:48px;
     }
  h4 {
    font-size: 20px;
    }

  p {
       font-size: 14px;
     }
  }


  ${(p)=>p.Conclusion && {
    textAlign: "center"
  }}
`;

export const MainSec2 = styled.div`
  display: grid;
  grid-gap: 5%;
  grid-template-columns: 1.5fr 1fr;
  align-items: center;

  @media (max-width: 1024px) {
    grid-template-columns: 1fr;
    grid-gap: 20px;
    justify-items:center;
  }

  @media (max-width: 480px) {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  ${customContainer}
`;

export const LeftSec2 = styled.div`
  text-align: left;
  margin:0;

  // p {
  //   font-size: 16px;
  //   font-weight: 400;

  //   @media (max-width: 1024px) {
  //     font-size: 16px;
  //   }

  //   @media (max-width: 768px) {
  //     font-size: 14px;
  //   }

  //   @media (max-width: 480px) {
  //     font-size: 12px;
  //   }
  }
`;

export const Framework = styled.div`
  font-size: 28px;
  font-weight: 600;
  margin: 30px 0 10px;
  border-top: 1px solid #dcdcdc;
  padding-top: 25px;
`;

export const RightSection2 = styled.img`
  max-width: 500px;
  width: 100%;
  height: auto;
`;

export const RightSec2 = styled.div`
  text-align: left;
  display: flex;
  // flex-direction: column;
  gap: 20px;

  @media (max-width: 576px) {
   flex-wrap:wrap;
  }
`;

export const HeadSec2Below = styled.div`
  h2 {
    margin: 0;
    font-size: 22px;
    font-weight: 500;
     @media (max-width: 576px) {
      font-size: 18px;
        }
  }

  h4 {
    color: #808080;
    margin: 0;
    font-size: 16px;
    font-weight: 400;
    margin-top: 10px;

    @media (max-width: 768px) {
      font-size: 16px;
    }

    @media (max-width: 576px) {
      font-size: 14px;
      margin-top:0px;
    }
  }
`;

export const Requirements = styled.div`
  background: #f1eaff;
  > div {
    width: 80%;
    text-align: center;
    padding: 60px 0;
    margin: 0 auto;
    @media(max-width:576px){
    width:90%;}

    p {
      font-size: 18px;
      width: 80%;
      margin: 0 auto;
      margin-top: 20px;
       @media(max-width:576px){
        font-size: 14px;
    width:100%;}
    }
  }
`;

export const AllMainSec3 = styled.div`
  background: #edf2f8;
  padding: 50px 0px;
  font-family: Arial, sans-serif;
  color: #333;

  @media screen and (max-width: 768px) {
    padding: 40px 20px;
  }

  @media screen and (max-width: 480px) {
    padding: 30px 20px;
  }
`;

export const MainSecHead3 = styled.div`
  text-align: center;
  margin-bottom: 30px;
`;

export const MainSecHead3H2 = styled.h2`
  margin: 0;
  font-size: 52px;
  font-weight: 500;
  color: #000000;

  @media screen and (max-width: 768px) {
    font-size: 2rem;
  }
`;

export const MainSec3Text1 = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr;
  align-items: center;
  margin-bottom: 0;

  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr;
    text-align: center;
  }
`;

export const MainSec3Text2 = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr;
  align-items: center;

  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr;
    text-align: center;
  }
`;

export const LeftSec3 = styled.div`
  // padding: 20px;
  text-align: left;

  @media screen and (max-width: 768px) {
    padding: 10px;
  }
`;

export const LeftSec3H4 = styled.h4`
  margin: 0;
  font-size: 40px;
  color: #1a1a1a;
  font-weight: 400;
  margin-bottom: 15px;

  @media screen and (max-width: 768px) {
    font-size: 1.5rem;
  }
`;

export const LeftSec3P = styled.p`
  font-size: 18px;
  font-weight: 400;
  color: #303030;
  line-height: 1.6;
  margin-bottom: 15px;

  @media screen and (max-width: 768px) {
    font-size: 0.95rem;
  }

  @media screen and (max-width: 480px) {
    font-size: 0.9rem;
  }
`;

export const LeftSec3Li = styled.li`
  font-size: 16px;
  font-weight: 400;
`;

export const LastParaSec3 = styled.div`
  padding: 10px 53px;
  text-align: center;
  font-size: 20px;
  font-weight: 400;

  @media screen and (max-width: 768px) {
    padding: 10px 10px;
  }
`;

export const LeftSec3Span = styled.span`
  font-size: 20px;
  font-weight: 500;
`;

export const RightSec3 = styled.div`
  text-align: center;
`;

export const RightSec3Img = styled.img`
  max-width: 100%;

  @media screen and (max-width: 768px) {
    width: 90%;
  }

  @media screen and (max-width: 480px) {
    width: 95%;
  }
`;

export const LineImg = styled.img`
  display: block;
  margin: 0 auto;
  width: 80%;

  @media screen and (max-width: 768px) {
    width: 90%;
  }

  @media screen and (max-width: 480px) {
    width: 95%;
  }
`;

export const AllMainSec4 = styled.div`
  background: #f1e6d8;
  padding: 70px 0px;

  @media (max-width: 1024px) {
    padding: 50px 60px;
  }

  @media (max-width: 768px) {
    padding: 40px 30px;
  }

  @media (max-width: 480px) {
    padding: 30px 20px;
  }
`;

export const MainSec4 = styled.div``;

export const Sec4MainHead = styled.div`
  h2 {
    margin: 0;
    font-size: 52px;
    font-weight: 500;
    color: #000000;
    text-align: left;

    @media (max-width: 1024px) {
      font-size: 42px;
    }

    @media (max-width: 768px) {
      font-size: 36px;
    }

    @media (max-width: 480px) {
      font-size: 28px;
    }
  }
`;

export const Paragraph = styled.p`
  text-align: left;
  font-size: 18px;
  font-weight: 400;
  color: #303030;

  @media (max-width: 1024px) {
    font-size: 16px;
  }

  @media (max-width: 768px) {
    font-size: 14px;
  }

  @media (max-width: 480px) {
    font-size: 12px;
  }
`;

export const Sec4Img = styled.div`
  img {
    width: 100%;
  }
`;

export const SectionHeader = styled.h4`
  margin: 15px;
  font-size: 40px;
  font-weight: 400;
  text-align: left;

  @media (max-width: 1024px) {
    font-size: 32px;
  }

  @media (max-width: 768px) {
    font-size: 28px;
  }

  @media (max-width: 480px) {
    font-size: 24px;
  }
`;

export const AllBtnSec4 = styled.div`
  display: flex;
  gap: 12px;

  @media (max-width: 768px) {
    flex-wrap: wrap;
    gap: 8px;
  }

  @media (max-width: 480px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const BtnSec4 = styled.div`
  button {
    background: #ffffff;
    border-radius: 140px;
    border: none;
    padding: 13px 15px;

    @media (max-width: 480px) {
      padding: 10px 12px;
    }
  }
`;

export const AllMainSec5 = styled.div`
  padding: 80px 0px;
  background: #f6f6f6;
 

  @media (max-width: 992px) {
padding: 50px 0px;
   }


`;

export const MainSec5 = styled.div`
  ${customContainer}
`;

export const Sec5Content = styled.div`
position:relative;
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 5px 0px;
  align-items: center;
  justify-content: space-between;
  justify-items: end;
 
  ${(p)=>p.second && {
    marginTop: "60px",
    justifyItems:'stretch'
     }}

  @media (max-width: 1024px) {
    padding: 5px 3px;
    gap: 10px;
  }

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
   
    justify-items: center;
    ${(p)=>p.second && {
     display:"flex",
     flexDirection:"column-reverse",
       marginTop: "30px"
       }}
  }

  @media (max-width: 480px) {
    padding: 0 px;
  }
`;

export const Sec5Content1 = styled(Sec5Content)`
  position: relative;
  grid-template-columns: 1fr 1fr;
  gap: 15px;
  justify-items: start;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

export const Sec5Img1 = styled.div`
  max-width: 180px;
  top: -85px;
  position: absolute;
  left: -38%;
  text-align: left;
  img {
    width: 100%;
  }

   @media (max-width:576px){
      top: -130px;
      left: -28%;
    }

  

`;

export const Sec5Img2 = styled.div`
  width: 200px;
  bottom: -55%;
  position: absolute;
  right: -16%;
  text-align: left;
  img {
    width: 100%;
  }
    @media (max-width:1024px){
       bottom: -38%;
    }

    @media (max-width:600px){
    display:none;}
`;

export const LeftSec5Content = styled.div`
  text-align: left;
  position: relative;

  // h2 {
  //   font-size: 45px;
  //   font-weight: 500;
  //   margin: 0;

  //   @media (max-width: 1024px) {
  //     font-size: 36px;
  //   }

  //   @media (max-width: 768px) {
  //     font-size: 30px;
  //   }

  //   @media (max-width: 480px) {
  //     font-size: 24px;
  //   }
  // }

  // h4 {
  //   font-size: 30px;
  //   font-weight: 500;
  //   margin: 7px 0 0;
  //   color: #303030;

  //   @media (max-width: 1024px) {
  //     font-size: 24px;
  //   }

  //   @media (max-width: 768px) {
  //     font-size: 20px;
  //   }

  //   @media (max-width: 480px) {
  //     font-size: 18px;
  //   }
  // }

  // p {
  //   font-size: 18px;
  //   font-weight: 400;
  //   color: #303030;

  //   @media (max-width: 1024px) {
  //     font-size: 16px;
  //   }

  //   @media (max-width: 768px) {
  //     font-size: 14px;
  //   }

  //   @media (max-width: 480px) {
  //     font-size: 12px;
  //   }
  // }
`;

export const RightSec5Content = styled.div`
  max-width: 500px;
  img {
    width: 100%;
    @media (max-width: 768px) {
      width: 100%;
    }
  }
`;

export const AllMainSec6 = styled.div`
  padding: 100px 0px;
  position: relative;

    @media (max-width: 992px) {
    padding: 50px 0px;
  }

  @media (max-width: 480px) {
    padding: 30px 0px;
  }
`;

export const MainSec6 = styled.div`
  gap: 10px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-items: end;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 1024px) {
    grid-template-columns: 1fr 1fr;
    gap: 20px;
  }

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    justify-items:center;
  }
  ${customContainer}
`;

export const LeftSec6 = styled.div`
  width: 200px;
  top: -100px;
  position: absolute;
  left: 0;
  text-align: left;
  img {
    width: 100%;
  }
    @media (max-width:1024px){
      width: 140px;
    top: -70px;
    }
     @media (max-width:576px){
      width: 110px;
    }
`;

export const RightSec6 = styled.div`
  max-width: 550px;
  img {
    width: 100%;
  }

  @media (max-width: 768px) {
    max-width:450px;
  }

`;

export const CenterSec6 = styled.div`
  padding-right: 30px;

  @media (max-width: 768px) {
    padding:0px;
    padding-top:15px;
  }

  // h2 {
  //   text-align: left;
  //   font-size: 52px;
  //   font-weight: 500;
  //   margin: 0px;

  //   @media (max-width: 1024px) {
  //     font-size: 42px;
  //   }

  //   @media (max-width: 768px) {
  //     font-size: 36px;
  //   }

  //   @media (max-width: 480px) {
  //     font-size: 30px;
  //   }
  // }

  // p {
  //   text-align: left;
  //   color: #303030;
  //   font-size: 18px;
  //   font-weight: 400;

  //   @media (max-width: 1024px) {
  //     font-size: 16px;
  //   }

  //   @media (max-width: 768px) {
  //     font-size: 14px;
  //   }

  //   @media (max-width: 480px) {
  //     font-size: 12px;
  //   }
  // }
`;

export const AllBoxSec6 = styled.div`
  gap: 15px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;

  @media (max-width: 1024px) {
    grid-template-columns: 1fr 1fr 1fr;
  }

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

export const BoxSec = styled.div`
  text-align: left;
  padding: 10px;
  border-radius: 16px;

  h1 {
    font-size: 52px;
    font-weight: 700;
    margin: 0px;

    @media (max-width: 768px) {
      font-size: 42px;
    }

    @media (max-width: 480px) {
      font-size: 32px;
    }
  }

  h4 {
    font-size: 20px;
    font-weight: 500;
    margin: 0px;

    @media (max-width: 768px) {
      font-size: 18px;
    }

    @media (max-width: 480px) {
      font-size: 16px;
    }
  }

  p {
    font-size: 16px;
    font-weight: 400;
    color: #303030;

    @media (max-width: 768px) {
      font-size: 14px;
    }

    @media (max-width: 480px) {
      font-size: 12px;
    }
  }
`;

export const BoxSec61 = styled(BoxSec)`
  background: #e0d1fe;

  h1 {
    color: #f1eaff;
  }
`;

export const BoxSec62 = styled(BoxSec)`
  background: #f1e6d8;

  h1 {
    color: #ecd3b4;
  }
`;

export const BoxSec63 = styled(BoxSec)`
  background: #b4d0f0;

  h1 {
    color: #e6e6e6;
  }
`;

export const upDown = keyframes`
  0%{
    transform: translateY(0); 
  }
  //   50% {
  //   transform: translateY(-100%); 
  // } 
  100% {
    transform: translateY(-100%); 
  }
`;

export const upDownReverse = keyframes`
  0% {
    transform: translateY(-100%); 
  }
  100% {
    transform: translateY(0%); 
  }
`;

// Styled components
export const AllMainSec7 = styled.div`
  overflow: hidden;
  max-height:800px;
  height: 100vh;
  background: #e4eaee;
  .mainsec7 img {
    width: 100%;
    display: block;
  }

  @media (min-width:768px) and (max-width:992px){
  max-height:600px;
  }
  @media (min-width:576px) and (max-width:767px){
  max-height:500px;
  }
`;

export const MainSec7 = styled.div`
  ${customContainer}
`;

export const AllImagesSec7 = styled.div`
  padding: 10px 0px;
  // background: #2E2E2E;
  display: grid;
  gap: 10px;
  grid-template-columns: repeat(5, 1fr);
  transform: rotate(-5deg) translateX(5%);

   @media (max-width:992px){
     grid-template-columns: repeat(4, 1fr);
      transform: rotate(-5deg)  translateX(15%);
    }

   @media (max-width:576px){
     grid-template-columns: repeat(2, 1fr);
      transform: rotate(-5deg)  translateX(45%);
    }

`;

export const Row = styled.div`
  img {
    width: 100%;
  }

  &.row1sec7 {
    animation: ${upDown} 8s linear infinite;
  }

  &.row2sec7 {
    animation: ${upDownReverse} 8s linear infinite;
  }

  &.row3sec7 {
    animation: ${upDown} 8s linear infinite;
  }

  &.row4sec7 {
    animation: ${upDownReverse} 8s linear infinite;
  }
`;

export const AllMainSec8 = styled.div`
  padding: 40px 48px;
  background: linear-gradient(180deg, #ededed 0%, #ffffff 100%);

  @media (max-width: 1024px) {
    padding: 30px 24px;
  }

  @media (max-width: 768px) {
    padding: 20px 16px;
  }

  @media (max-width: 480px) {
    padding: 15px 10px;
  }
`;

export const MainSec8 = styled.div``;

export const MainHeadSec8 = styled.div`
  h2 {
    font-size: 52px;
    font-weight: 500;
    margin: 0;
    text-align: left;
    color: #1a1a1a;

    @media (max-width: 1024px) {
      font-size: 42px;
    }

    @media (max-width: 768px) {
      font-size: 36px;
    }

    @media (max-width: 480px) {
      font-size: 28px;
    }
  }
`;

export const Paragraph2 = styled.p`
  text-align: left;
  font-size: 20px;
  font-weight: 400;
  color: #303030;

  @media (max-width: 1024px) {
    font-size: 18px;
  }

  @media (max-width: 768px) {
    font-size: 16px;
  }

  @media (max-width: 480px) {
    font-size: 14px;
  }
`;

export const Product = styled.div`
  padding: 15px 10px;
  display: flex;
  justify-content: space-between;
  border: 1px solid #dcdcdc;

  h4,
  span {
    font-size: 20px;
    font-weight: 400;
    color: #808080;
    margin: 0;
  }

  @media (max-width: 1024px) {
    flex-wrap: wrap;
    padding: 10px 8px;

    h4,
    span {
      font-size: 18px;
    }
  }

  @media (max-width: 768px) {
    padding: 8px 6px;

    h4,
    span {
      font-size: 16px;
    }
  }

  @media (max-width: 480px) {
    flex-direction: column;
    text-align: left;
    padding: 6px 4px;

    h4,
    span {
      font-size: 14px;
    }
  }
`;

export const ProductManage = styled(Product)`
  border-bottom: 1px solid #dcdcdc;
`;

export const AllMainSec9 = styled.div`
  padding: 45px 35px;

  @media (max-width: 768px) {
    padding: 30px 20px;
  }

  @media (max-width: 480px) {
    padding: 20px 10px;
  }
`;

export const AllBoxSec9 = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  border-width: 1px 1px 0px 1px;
  border-style: solid;
  border-color: #dcdcdc;
  padding-left: 5px;

  @media (max-width: 1024px) {
    gap: 20px;
    padding-left: 0;
  }

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    gap: 15px;
  }
`;

export const ImageSec9 = styled.div`
  border-width: 0px 1px 0px 1px;
  border-style: solid;
  border-color: #dcdcdc;

  img {
    width: 100%;
  }
`;

export const Box = styled.div`
  padding: 5px;
  text-align: left;

  @media (max-width: 480px) {
    padding: 10px;
  }

  h2 {
    font-size: 30px;
    font-weight: 500;

    @media (max-width: 1024px) {
      font-size: 26px;
    }

    @media (max-width: 768px) {
      font-size: 22px;
    }

    @media (max-width: 480px) {
      font-size: 20px;
    }
  }

  span {
    font-size: 20px;
    font-weight: 400;
    color: #808080;

    @media (max-width: 1024px) {
      font-size: 18px;
    }

    @media (max-width: 768px) {
      font-size: 16px;
    }

    @media (max-width: 480px) {
      font-size: 14px;
    }
  }

  p {
    font-size: 16px;
    font-weight: 400;
    color: #303030;

    @media (max-width: 1024px) {
      font-size: 14px;
    }

    @media (max-width: 768px) {
      font-size: 14px;
    }

    @media (max-width: 480px) {
      font-size: 12px;
    }
  }
`;

export const Box2 = styled(Box)`
  border: 1px solid #dcdcdc;
`;

// export const  upDown = keyframes`
//   0%, 100% {
//     transform: translateY(0);
//   }
//   50% {
//     transform: translateY(-100px);
//   }
// `;

// export const  upDownReverse = keyframes`
//   0%, 100% {
//     transform: translateY(0);
//   }
//   100% {
//     transform: translateY(100px);
//   }
// `;

// Styled components
export const AllMainSec10 = styled.div`
  .mainsec10 img {
    width: 100%;
    display: block;
  }
`;

export const AllImagesSec10 = styled.div`
  padding: 10px 15px;
  background: #000;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  overflow: hidden;
`;

// export const  Row = styled.div`
//   img {
//     width: 100%;
//   }

//   &.imagesec10row1 img {
//     animation: ${upDown} 3s ease-in-out infinite;
//   }

//   &.imagesec10row2 img {
//     animation: ${upDownReverse} 3s ease-in-out infinite;
//   }

//   &.imagesec10row3 img {
//     animation: ${upDown} 3s ease-in-out infinite;
//   }

//   &.imagesec10row4 img {
//     animation: ${upDownReverse} 3s ease-in-out infinite;
//   }
// `;

export const AllMainSec11 = styled.div`
  padding: 100px 0px;

  @media (max-width: 1024px) {
    padding: 50px 0px;
  }

  @media (max-width: 768px) {
    padding: 40px 0px;
  }
`;

export const MainSec11 = styled.div`
      display: grid;
    gap: 5px;
    grid-template-columns: 1fr 1fr;
    width: 80%;
    margin: 0 auto;
    align-items: center;
    justify-items: center;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    gap:30px;
  }
  ${customContainer}
`;

export const LeftSec11 = styled.div`
  // h2 {
  //   font-size: 52px;
  //   font-weight: 500;
  //   text-align: left;
  //   margin: 0px;

  //   @media (max-width: 768px) {
  //     font-size: 35px;
  //   }
  //   @media (max-width: 480px) {
  //     font-size: 31px;
  //   }
  // }

  p {
    text-align: left;
    font-size: 18px;
    font-weight: 400;
  }
`;

export const OutcomeSec11 = styled.div`
  gap: 10px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;

  @media (max-width: 768px) {
    gap: 15px;
    grid-template-columns: 1fr;
  }
`;

export const OutcomeSec = styled.div`
  p {
    text-align: center;
    font-size: 16px;
    font-weight: 400;
    color: #808080;
    margin:0;
  }

  h4 {
    text-align: center;
    font-size: 32px;
    font-weight: 500;
    margin: 0px;

    span {
      font-size: 15px;
    }

    @media (max-width: 480px) {
      font-size: 25px;
    }
  }
`;

export const OutcomeSec2 = styled(OutcomeSec)`
  border-left: 2px solid #d9d9d9;
  border-right: 2px solid #d9d9d9;
  padding: 0 5px;
  @media (max-width:768px){
  border:none;}
`;

export const RightSec11 = styled.div`
  max-width: 550px;
  img {
    width: 100%;
  }
`;

export const AllMainSec12 = styled.div`
  padding: 80px 0px;
  background: #eeeeee;

  @media (max-width: 768px) {
    padding: 35px 10px;
    background: #eeeeee;
  }
`;

export const MainSec12 = styled.div`
  ${customContainer}
  text-align:center;
  p {
    margin-top: 0;
    font-size: 32px;
    font-weight: 400;
    color: #000000;

      @media (max-width: 1300px) {
      font-size: 20px;
    }
    @media (max-width: 576px) {
      font-size: 16px;
    }
  }

  h4 {
    margin: 0px;
    font-size: 32px;
    font-weight: 500;
     @media (max-width: 576px) {
      font-size: 22px;
    }
  }

  span {
    font-size: 16px;
    font-weight: 400;
    color: #444444;
  }
`;

export const AllMainSec13 = styled.div`
  background: url("/images/cc-conclusionbg.png");
  background-size: 100% 100%;
  padding: 40px 0px;

  @media (max-width: 768px) {
    padding: 23px 0px;
  }
`;

export const MainSec13 = styled.div`
  ${customContainer}
  text-align:center;
  // h2 {
  //   margin: 0px;
  //   font-size: 52px;
  //   font-weight: 500;
  // }

  // p {
  //   line-height: 30px;
  //   font-size: 20px;
  //   font-weight: 400;
  //   max-width: 800px;
  //   margin: 0 auto;
  //   margin-top: 30px;
  //   margin-bottom: 30px;
    
  //   @media (max-width: 576px) {
  //     font-size: 18px;
  //   }
  // }

 
  }
`;

export const GetInTouchBtn = styled.div`
  button {
  font-family:DM Sans;
    font-size: 18px;
    font-weight: 500;
    background: #000000;
    padding: 15px;
    color: #ffffff;
    border: none;
    cursor: pointer;
    transition: background 0.3s ease;

    &:hover {
      background: #333333;
    }
  }
`;
