import React, {useState } from "react";
import './App.css';
import Navbar from "./Component/Navbar";
import Footer from "./Component/Footer";
import Home from "./Pages/Home/Home";
import { Route, Routes} from "react-router-dom";
import  * as Router from './Routes';
import AboutUs from "./Pages/AboutUs/AboutUs";
import Game from "./Pages/Game/Game";
import Blockchain from "./Pages/Blockchian/Blockchain";
import OurWork from "./Pages/OurWork/OurWork";
import NewOurWork from './Pages/OurWork/NewOurWork'
import WebDevelopment from "./Pages/WebDevelopment/WebDevelopment";
import AppDevelopment from "./Pages/AppDevelopment/AppDevelopment";
import Metaverse from "./Pages/Metaverse/Metaverse";
import ContactUs from "./Pages/ContactUs/ContactUs";
import TermsAndCondition from "./Pages/TermsAndContion/TermsAndCondition";
import PrivacyAndPolicy from "./Pages/PrivacyAndPolicy/PrivacyAndPolicy";
import Ethereum from "./Pages/Ethereum/Ethereum";
import CryptoCurrency from "./Pages/CryptoCurrency/CryptoCurrency";
import NftMarketPlace from "./Pages/NftMarketPlace/NftMarketPlace";
import Nft from "./Pages/Nfts/Nft";
import ExchangeCrypto from "./Pages/ExchangeCrypto/ExchangeCrypto";
import ScrollToTop from "./Component/ScrollToTop ";
import { WhatsappIcon } from "./Component/WhatsappIcon";
import Thankyou from "./Pages/AboutUs/Thankyou";
import Application from "./Pages/Careers/Application";
import Careers from "./Pages/Careers/Careers";
import JobList from "./Pages/Careers/JobList";
import { JobDescription } from "./Pages/Careers/JobDescription";
import { ToastContainer } from "react-toastify";
import { AllJobs } from "./Pages/Careers/AllJobs";
import { MainServicePage } from "./Component/NewServices/MainServicePage";
import { CaseStudy } from "./Component/CaseStudty/CaseStudy";
import Team from "./Component/Team/Team";


function App() {
  const [showDropDown,setShowDropDown]= useState(false);
  
  return (
      <div onClick={() => setShowDropDown(false)}>  
          <Navbar showDropDown={showDropDown} setShowDropDown={setShowDropDown}/>
          <ScrollToTop />
          <Routes>
            <>
           <Route path={Router.HOME} element={<Home/>}/>
           <Route path={Router.ABOUTUS} element={<AboutUs/>}/>
           <Route path={Router.THANKYOU} element={<Thankyou/>}/>
           <Route path={Router.CONTACTUS} element={<ContactUs/>}/>
           <Route path={Router.GAME} element={<Game/>}/>
           <Route path={Router.BLOCKCHAIN} element={<Blockchain/>}/>
           <Route path={Router.WEBDEVELOPEMENT} element={<WebDevelopment/>}/>
           <Route path={Router.APPDEVELOPMENT} element={<AppDevelopment/>} />
           <Route path={Router.OURWORK} element={<NewOurWork/>}/>
           <Route path={Router.METAVERSEDEVELOPMENT} element={<Metaverse/>}/>
           <Route path={Router.TERMSANDCONDITIONS} element={<TermsAndCondition/>}/>
           <Route path={Router.PRIVACYANDPOLICY} element={<PrivacyAndPolicy/>}/>
           <Route path={Router.ETHEREUM} element={<Ethereum/>}/>
           <Route path={Router.CRYPTOCURRENCY} element={<CryptoCurrency/>}/>
           <Route path={Router.NFTMARKETPLACE} element={<NftMarketPlace/>} />
           <Route path={Router.NFT} element={<Nft/>}/>
           <Route path={Router.EXCHANGECRYPTO} element={<ExchangeCrypto/>}/>
           <Route path={Router.APPLICATION} element={<Application/>}/>
           <Route path={Router.CAREERS} element={<Careers/>}/>
           <Route path={Router.DEFAULT} element={<Home/>}/>
           <Route path={Router.SWIPER} element={<JobList/>}/>
           <Route path={Router.JOBDESCRIPTION} element={<JobDescription/>}/>
           <Route path={Router.ALLJOBS} element={<AllJobs/>}/>
           <Route path={Router.SERVICES} element={<MainServicePage/>}/>
           <Route path={Router.CASE_STUDY} element={<CaseStudy/>}/>
           <Route path={Router.TEAM} element={<Team/>}/>
           </>
          </Routes>
          <Footer/>
          <WhatsappIcon />
          <ToastContainer
        position="top-right"
        autoClose={3000} 
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      /> 
      </div>
  );
}

export default App;
